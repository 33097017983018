import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import idx from 'idx';

const Context = React.createContext({
  navItems: [],
  logo: { file: {} },
  primaryColor: '',
  secondaryColor: '',
  footerText: { json: {} },
  phone: '',
  email: '',
  companyName: '',
});

export const Provider = ({ children }) => (
  <StaticQuery
    query={graphql`
      query ContentfulQuery {
        allContentfulSite {
          nodes {
            favicon {
              file {
                url
              }
            }
            homepageHeroImageDesktop {
              file {
                url
              }
            }
            homepageHeroImageMobile {
              file {
                url
              }
            }
            heroTitle
            companyName
            navItems {
              links {
                title
                slug
              }
              title
            }
            footerText {
              json
            }
            phone
            email
            primaryColor
            secondaryColor
            logo {
              title
              file {
                url
              }
            }
            typeform
            homepageSections {
              layout
              contentGroups {
                button {
                  text
                  slug
                  colorScheme {
                    backgroundColor
                    textColor
                  }
                  typeform
                }
                title
                text {
                  text
                }
                colorScheme {
                  backgroundColor
                  textColor
                }
                image {
                  title
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      if (!data.allContentfulSite) {
        return null;
      }
      const node = data.allContentfulSite.nodes[0];
      const favicon = idx(node, (_) => _.favicon.file.url);
      return (
        <Context.Provider value={node}>
          {favicon && (
            <Helmet>
              <link rel="icon" href={favicon} />
            </Helmet>
          )}
          {children}
        </Context.Provider>
      );
    }}
  />
);

export default Context;
